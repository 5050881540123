/** A component to wrap all components that should be present in the HTML-header
    note: When naming this component 'Header' instead of 'Top' the page will break.
    Native HTML-Tag names are not allowed for Vue components. **/

<template>
  <header>
    <b-navbar
      toggleable="lg"
      :type="type"
      :variant="variant"
      class="navbar-container mb-3 border-bottom"
    >
      <!-- Brand -->
      <div class="container-fluid content-wrapper position-relative mx-auto">
        <b-navbar-brand>
          <slot name="brand">
            <app-link
              class="d-inline-block"
              :to="{ path: '/'}"
            >
              <div class="project-title-container">
                <img
                  src="@/assets/img/piveau-logo-white.svg"
                  class="piveau-logo"
                >
              </div>
            </app-link>
          </slot>
        </b-navbar-brand>

        <!-- Collapse toggle button -->
        <b-navbar-toggle target="nav-collapse" />

        <!-- Navbar items -->
        <b-collapse
          id="nav-collapse"
          is-nav
        >
          <b-navbar-nav class="left-navbar">
            <slot name="items">
              <app-link
                class="nav-link"
                :to="{ path: '/datasets'}"
              >
                {{ $t('message.header.navigation.data.datasets') }}
              </app-link>
              <app-link
                class="nav-link"
                :to="{ path: '/catalogues'}"
              >
                {{ $t('message.header.navigation.data.catalogues') }}
              </app-link>
              <!-- Default slot for additional items -->
              <slot />
            </slot>
          </b-navbar-nav>

          <!-- Slot for navbar content -->
          <slot name="body" />

          <!-- Right aligned nav items -->
          <b-navbar-nav
            class="right-navbar ml-auto"
          >
            <slot name="right-items" />
            <b-nav-item-dropdown
              v-if="withLanguageSelect"
              class="language-select"
              :text="$t('message.spokenLanguage')"
              right
            >
              <b-dropdown-item
                v-for="locale in locales"
                :key="locale"
                :to="{ query: { lang: locale } }"
                exact-active-class=""
                active-class=""
                exact
              >
                {{ $t('message.spokenLanguage', locale) }}
              </b-dropdown-item>
            </b-nav-item-dropdown>

            <!-- Slot for authentication content -->
            <slot name="login">
              <div v-if="withLogin">
                <app-link
                  v-if="!authenticated"
                  class="nav-link"
                  target="_self"
                  to="#"
                  @click.native.prevent="login"
                >
                  <div class="d-inline-block">
                    <b-icon-person-fill />
                    {{ $t('message.header.subnav.login') }}
                  </div>
                </app-link>
                <b-nav-item-dropdown
                  v-else
                  class="profile-select"
                  right
                >
                  <template #button-content>
                    <div class="d-inline-block">
                      <b-icon-person-fill />
                      {{ $keycloak.userName }}
                    </div>
                  </template>
                  <b-dropdown-item
                    exact-active-class=""
                    active-class=""
                    exact
                    @click="createDataset"
                  >
                    <b-icon-file-plus />
                    {{ $t('message.datasets.createDataset') }}
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item
                    exact-active-class=""
                    active-class=""
                    exact
                    @click.prevent="logout"
                  >
                    <b-icon-arrow-bar-right />
                    {{ $t('message.header.subnav.logout') }}
                  </b-dropdown-item>
                </b-nav-item-dropdown>
              </div>
            </slot>
          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>
  </header>
</template>

<script>
import AppLink from './AppLink';

export default {
  name: 'Top',
  components: {
    appLink: AppLink,
  },
  props: {
    type: {
      type: String,
      default: 'dark'
    },
    variant: {
      type: String,
      default: 'primary'
    },
    withLogin: {
      type: Boolean,
      default: true,
    },
    withLanguageSelect: {
      type: Boolean,
      default: true,
    },
    locales: {
      type: Array,
      default() {
        return ['de', 'en'];
      }
    }
  },
  data: function () {
    return {
      authenticated: this.withLogin && this.$keycloak.authenticated
    }
  },
  methods: {
    login () {
      this.$keycloak.login();
    },
    logout () {
      this.$keycloak.logoutFn();
    },
    createDataset () {
      this.$router.push({ name: 'UploadPageDatasetDetails' });
    }
  }
};
</script>

<style lang="scss" scoped>
  @import '~@/styles/bootstrap_theme';

  .navbar-container {
    .content-wrapper {
      padding-left: 15px;
      padding-right: 15px;
    }
    .project-title-container {
      position: inline-block;
      vertical-align: top;
      text-align: right;

      .piveau-logo {
          width: 120px;
        }
        .project-name {
        text-transform: uppercase;
        font-size: 0.8em;
        font-weight: bold;
        margin-top: -10px;
      }
    }

    .navbar-nav {
      font-size: 1.1rem;
      font-weight: bold;
    }

    // .right-navbar {
    //   .language-select {
    //     font-size: 1.0rem;
    //   }
    // }
  }


  .brand-name {
    line-height: 48px;
  }
</style>
