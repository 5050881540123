// Base layout for 'detail' pages e.g. DatasetDetails, Imprint, UserPrivacy, etc.

<template>
  <div :class="{'detail-page-wrapper': $route.name !== 'Home', 'shadow-sm': $route.name !== 'Home'}">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'DetailPageLayout',
}
</script>

<style lang="scss">
  @import '~@/styles/bootstrap_theme.scss';
  .detail-page-wrapper {
      padding-top: 1rem;
      padding-bottom: 2rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      background-color: $bg-details-page;
  }

  .content {
    width: 60%;
  }
  img{
    width: 100%;
  }
  h1 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  h2, h3 {
    margin-top: 40px;
  }
  figcaption {
    font-style: italic;
  }
  hr.black-divider {
    border: 1px solid black;
  }
  @media only screen and (max-width: 1000px) {
    .content {
      width: 100%;
    }
  }
</style>
