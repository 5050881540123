import datasetService from '../src/piveau-ui-adapter-vhub/datasets';
import catalogueService from '../src/piveau-ui-adapter-vhub/catalogues';
import distributionService from '../src/piveau-ui-adapter-vhub/distributions';
import datastoreService from '../src/piveau-ui-adapter-vhub/datastore';
import gazetteerService from '../src/piveau-ui-adapter-vhub/gazetteer';
import uploadService from '../src/services/uploads';
import authService from '../src/auth/authService';
import i18n from './i18n/i18n.json';

const glueConfig = {
  title: "Prototyp SIS",
  description: "Prototyp SIS",
  keywords: "Prototyp SIS",
  api: {
    baseUrl: "https://piveau-hub-search-gsi.apps.osc.fokus.fraunhofer.de/",
    similarityBaseUrl: "https://www.ppe-aws.europeandataportal.eu/api/similarities/",
    gazetteerBaseUrl: null,
    uploadBaseUrl: "https://www.ppe-aws.europeandataportal.eu/data/api/",
    hubUrl: "https://piveau-hub-repo-gsi.apps.osc.fokus.fraunhofer.de/",
    matomoUrl: null,
    matomoSiteId: 1,
    catalogBaseUrl: "",
    authToken: ""
  },
  useAuthService: false,
  keycloak: {
    enableLogin: false,
    realm: "gsi",
    url: "https://keycloak-windnode-dev.okd.fokus.fraunhofer.de/auth",
    "ssl-required": "external",
    clientId: "windnode-ui",
    "public-client": true,
    "verify-token-audience": true,
    "use-resource-role-mappings": true,
    "confidential-port": 0
  },
  rtp: {
    grand_type: "urn:ietf:params:oauth:grant-type:uma-ticket",
    audience: "piveau-hub"
  },
  languages: {
    useLanguageSelector: false,
    locale: "de",
    fallbackLocale: "en"
  },
  services: {
    catalogueService,
    datasetService,
    distributionService,
    datastoreService,
    gazetteerService,
    uploadService,
    authService
  },
  themes: {
    header: "dark"
  },
  routerOptions: {
    base: "/",
    mode: "history"
  },
  navigation: {
    top: {
      main: {
        home: {
          // href: 'https://link-to-external-url.com' (optional)
          // target: ['_self' | '_blank'] (optional)
          show: true
        },
        data: {
          show: true
        },
        maps: {
          show: false
        },
        about: {
          show: false
        },
        append: [
          {
            href: "https://www.fokus.fraunhofer.de/datenschutz",
            target: "_self",
            title: "Privacy Policy"
          },
          {
            href: "https://www.fokus.fraunhofer.de/9663f8cb2d267d4b",
            target: "_self",
            title: "Imprint"
          }
        ],
        icons: false
      },
      sub: {
        privacyPolicy: {
          show: false,
          href: "https://www.fokus.fraunhofer.de/datenschutz",
          target: "_self"
        },
        imprint: {
          show: false,
          href: "https://www.fokus.fraunhofer.de/9663f8cb2d267d4b",
          target: "_self"
        }
      }
    },
    bottom: {}
  },
  images: {
    top: [
      {
        src: "https://i.imgur.com/lgtG4zB.png",
        // href: 'https://my-url.de'(optional)
        // target: ['_self' | '_blank'] (optional)
        description: "Logo European data portal",
        height: "60px",
        width: "auto"
      }
    ],
    bottom: []
  },
  datasets: {
    facets: {
      useDatasetFacetsMap: false,
      defaultFacetOrder: ["type", "legalBase", "geocodingDescription", "keywords", "format", "minDate", "maxDate"]
    }
  },
  datasetDetails: {
    distributions: {
      // Configurations that changes the way distributions are displayed.

      // If true, always display all distributions
      displayAll: false,
      // Number of first distributions to be shown before more need to be loaded.
      // Has no effect if displayAll is set to true.
      displayCount: 15,
      // Number of increment steps to be shown.
      // Has no effect if displayAll is set to true.
      incrementSteps: [20, 50]
    }
  }
}

export { glueConfig, i18n };
