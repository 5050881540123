/* eslint-disable */
import Vue from 'vue';
import Router from 'vue-router';
import store from './store/index';
// Import glue-config.js
import { glueConfig as GLUE_CONFIG } from '../config/user-config';
import { decode } from './utils/jwt';
import { i18n } from './i18n.js'

const Datasets = () => import(/* webpackChunkName: "Datasets" */'@/components/pages/Datasets');
const DatasetDetails = () => import(/* webpackChunkName: "DatasetDetails" */'@/components/pages/DatasetDetails/index');
const DatasetDetailsDataset = () => import(/* webpackChunkName: "DatasetDetailsDataset" */'@/components/pages/DatasetDetails/DatasetDetailsDataset');
const DatasetDetailsCategories = () => import(/* webpackChunkName: "DatasetDetailsCategories" */'@/components/piveau-ui-core/EDP2-datasetDetails-categories');
const DatasetDetailsSimilarDatasets = () => import(/* webpackChunkName: "DatasetDetailsSimilarDatasets" */'@/components/piveau-ui-core/EDP2-datasetDetails-similarDatasets');
// const DatasetDetailsActivityStream = () => import(/* webpackChunkName: "DatasetDetailsActivityStream" */'@/components/piveau-ui-core/EDP2-datasetDetails-activityStream');
// const DistributionDetails = () => import(/* webpackChunkName: "DistributionDetails" */'@/components/piveau-ui-core/DistributionDetails');
const Catalogues = () => import(/* webpackChunkName: "Catalogues" */'@/components/pages/Catalogues');
const Home = () => import(/* webpackChunkName: "Home" */'@/components/pages/Home');
const Imprint = () => import(/* webpackChunkName: "Imprint" */'@/components/pages/Imprint');
const About = () => import(/* webpackChunkName: "Imprint" */'@/components/pages/About');
const Help = () => import(/* webpackChunkName: "Helo" */'@/components/pages/Help');
const Glossary = () => import(/* webpackChunkName: "Helo" */'@/components/pages/Glossary');
const PrivacyPolicy = () => import(/* webpackChunkName: "PrivacyPolicy" */'@/components/pages/PrivacyPolicy');
const MapBasic = () => import(/* webpackChunkName: "MapBasic" */'@/components/piveau-ui-core/MapBasic');
const Auth = () => import(/* webpackChunkName: "Auth" */'@/components/piveau-ui-core/Auth');
const UploadPage = () => import(/* webpackChunkName: "UploadPage" */'@/components/piveau-ui-core/Upload/EDP2-uploadPage');
const UploadPageDatasetDetails = () => import(/* webpackChunkName: "UploadPageDatasetDetails" */'@/components/piveau-ui-core/Upload/EDP2-uploadPage-datasetDetails');
const UploadPageDistributions = () => import(/* webpackChunkName: "UploadPageDistributions" */'@/components/piveau-ui-core/Upload/EDP2-uploadPage-distributions');
const UploadPageFileUpload = () => import(/* webpackChunkName: "UploadPageFileUpload" */'@/components/piveau-ui-core/Upload/EDP2-uploadPage-fileUpload');
const UploadPageReview = () => import(/* webpackChunkName: "UploadPageReview" */'@/components/piveau-ui-core/Upload/EDP2-uploadPage-review');
const NotFound = () => import(/* webpackChunkName: "NotFound" */'@/components/pages/Datasets');

Vue.use(Router);

const title = GLUE_CONFIG.title;

const router = new Router({
  base: GLUE_CONFIG.routerOptions.base,
  mode: GLUE_CONFIG.routerOptions.mode,
  linkActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    if (to.matched.some(route => route.meta.scrollTop)) return { x: 0, y: 0 };
    else if (savedPosition) return savedPosition;
    else return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      redirect: { name: 'Home' },
      meta: {
        title,
      },
    },
    {
      path: '/datasets',
      name: 'Datasets',
      component: Datasets,
      meta: {
        title,
      },
    },
    {
      path: '/datasets/:ds_id',
      component: DatasetDetails,
      children: [
        {
          path: '',
          name: 'DatasetDetailsDataset',
          components: {
            datasetDetailsSubpages: DatasetDetailsDataset,
          },
          meta: {
            title,
          },
        },
        {
          path: 'categories',
          name: 'DatasetDetailsCategories',
          components: {
            datasetDetailsSubpages: DatasetDetailsCategories,
          },
          meta: {
            title,
          },
        },
        {
          path: 'similarDatasets',
          name: 'DatasetDetailsSimilarDatasets',
          components: {
            datasetDetailsSubpages: DatasetDetailsSimilarDatasets,
          },
          meta: {
            title,
          },
        },
      ],
      meta: {
        title,
      },
    },
    {
      path: '/home',
      name: 'Home',
      component: Home,
      meta: {
        title,
      },
    },
    {
      path: '/about',
      name: 'About',
      component: About,
      title,
    },
    {
      path: '/help',
      name: 'Help',
      component: Help,
      title,
    },
    {
      path: '/glossary',
      name: 'Glossary',
      component: Glossary,
      title,
    },
    {
      path: '/imprint',
      name: 'Imprint',
      component: Imprint,
      title,
    },
    {
      path: '/privacypolicy',
      name: 'PrivacyPolicy',
      component: PrivacyPolicy,
      meta: {
        title,
      },
    },
    {
      path: '/maps',
      name: 'MapBasic',
      component: MapBasic,
      meta: {
        title,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: Auth,
      meta: {
        title,
        requireAuth: true,
      },
    },
    {
      path: '/upload',
      component: UploadPage,
      children: [
        {
          path: '',
          redirect: {
            path: 'dataset'
          },
        },
        {
          path: 'dataset',
          name: 'UploadPageDatasetDetails',
          components: {
            uploadSubpages: UploadPageDatasetDetails,
          },
          meta: {
            title,
          },
          props: {
            currentStep: 1,
          },
        },
        {
          path: 'distribution',
          name: 'UploadPageDistributions',
          components: {
            uploadSubpages: UploadPageDistributions,
          },
          meta: {
            title,
          },
          props: {
            currentStep: 2,
          },
        },
        {
          path: 'fileUpload',
          name: 'UploadPageFileUpload',
          components: {
            uploadSubpages: UploadPageFileUpload,
          },
          meta: {
            title,
          },
          props: {
            currentStep: 3,
          },
        },
        {
          path: 'review',
          name: 'UploadPageReview',
          components: {
            uploadSubpages: UploadPageReview,
          },
          meta: {
            title,
          },
          props: {
            currentStep: 4,
          },
        },
      ],
      meta: {
        title,
        requiresAuth: true,
        scrollTop: true,
      },
    },
    {
      path: '*',
      name: 'NotFound',
      component: NotFound,
      meta: {
        title,
      },
    },
  ]
});

router.beforeEach((to, from, next) => {

  // Hash mode backward-compatibility
  // Fixes https://gitlab.fokus.fraunhofer.de/viaduct/organisation/issues/432
  if (to.redirectedFrom && to.redirectedFrom.substr(0, 3) === '/#/') {
    let path = to.redirectedFrom.substr(2);
    const base = `${GLUE_CONFIG.routerOptions.base}/`;
    if (path.startsWith(base)) {
      // Restore standard Vue behavior when navigated to '/#/base'
      // so you are redirected to '/base' instead of '/base/base'
      path = '/';
    }
    next({ path, replace: true });
    return;
  }

  // RDF|N3|JSON-LD redirects
  if (/^\/(data\/)?datasets\/[a-z0-9-_]+(\.rdf|\.n3|\.jsonld|\.ttl|\.nt)/.test(to.path)) {
    let locale = to.query.locale ? `&locale=${to.query.locale}` : '';
    window.location = `${router.app.$env.api.hubUrl}${to.path}?useNormalizedId=true${locale}`;
  }

  if (/^\/(data\/)?api\/datasets\/[a-z0-9-_]+(\.rdf|\.n3|\.jsonld|\.ttl|\.nt)/.test(to.path)) {
    let locale = to.query.locale ? `?locale=${to.query.locale}` : '';
    let returnPath = to.path.replace('/api', '')
      .replace(/(\.rdf|\.n3|\.jsonld|\.ttl|\.nt)/, '')
      .replace('?useNormalizedId=true', '');
    window.location = `${window.location.protocol}//${window.location.host}${GLUE_CONFIG.routerOptions.base}${returnPath}${locale}`;
  }

  // Set language
  let lang = to.query.lang;
  if (!lang) {
    lang = from.query.lang || i18n.locale || i18n.fallbackLocale;
    to.query.lang = lang;
    i18n.locale = lang;
    next(to);
  }
  if (lang && lang !== i18n.locale) {
    i18n.locale = lang;
  }

  // Authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const auth = router.app.$env.useAuthService
      ? router.app.$keycloak.authenticated
      : null;
    if (!auth) {
      // we can show unauthorized page here for requireAuth Meta
      next({ name: 'Datasets' });
    } else {
      // Checking the role allowed in rtpToken
      router.app.$keycloak.getRtpToken().then((rtpToken) => {
        const decodedAccessToken = decode(rtpToken.data.access_token);
        if (decodedAccessToken.realm_access.roles.includes('provider')) {
          next();
          return;
        } else {
          next({name: 'Datasets'});
        }
      });
    }
  } else {
    document.title = to.meta.title;
    next();
  }
});

export default router;
