
/*
The header component.

Customize the default header component or create your own here.
*/

<template>
  <div class="gsi-header shadow-sm">
    <div class="d-flex">
      <div class="gsi-header__overline w-75 bg-primary" />
      <div class="gsi-header__overline w-25 bg-primary2" />
    </div>
    <top
      class="gsi-header__nav"
      type="light"
      variant=""
      :with-login="$env.useAuthService"
      :with-language-select="$env.languages.useLanguageSelector"
    >
      <!-- Use default header but with different logo -->
      <template #brand>
        <app-link
          :to="{ path: '/'}"
        >
          <img
            src="@/assets/img/SIS-redblue-curves.svg"
            class="senias-logo"
            alt="Logo"/>
        </app-link>
      </template>
      <!-- Customize header menu items -->
      <template #items>
        <app-link
          to="https://dashboards.sozial-informations-system.de/"
          target="_blank"
          class="nav-link"
        >
          Dashboards
        </app-link>
        <app-link
          class="nav-link"
          :to="{ path: '/datasets'}"
        >
          {{ $t('message.header.navigation.data.datasets') }}
        </app-link>
        <app-link
          class="nav-link"
          :to="{ path: '/about'}"
        >
          Über das Portal
        </app-link>
        <app-link
          class="nav-link"
          :to="{ path: '/help'}"
        >
          Hilfe
        </app-link>
        <app-link
          class="nav-link"
          :to="{ path: '/glossary'}"
        >
          Glossar
        </app-link>
      </template>
    </top>
  </div>
</template>

<script>
import Top from '@/components/piveau-ui-core/Top';
import AppLink from '@/components/piveau-ui-core/AppLink';

export default {
  name: 'TheHeader',
  components: {
    Top,
    AppLink
  },
}
</script>

<style lang="scss">
.senias-logo{
    width: 150px;
  }
  .gsi-header {
      max-width: 1300px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 15px;
    &__overline {
      height: .3125rem;
    }
    &__brand-icon-placeholder {
      height: 30px;
      width: 130px;
    }
    &__nav nav.navbar-container {
      padding: 0 1rem;
      background-color: #c7d9ff;
      .navbar-nav {
        font-size: 1rem;
        font-weight: normal;
        a.nav-link {
          margin-right: 0.5rem;
          .active {
            font-weight: bold;
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .content-wrapper {
        padding-left: 0;
        padding-right: 0;
      }

    }
  }
</style>
